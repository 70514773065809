// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export type GroupIconProps = React.ComponentProps<"svg"> & {
  title?: string
}

export function GroupIcon(props: GroupIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.916 0c-2.1 0-4.113.843-5.597 2.343A8.043 8.043 0 000 8c0 2.122.834 4.157 2.319 5.657A7.874 7.874 0 007.916 16c2.1 0 4.113-.843 5.598-2.343A8.044 8.044 0 0015.832 8a8.043 8.043 0 00-2.319-5.657A7.874 7.874 0 007.917 0zm3.463 7.5c.132 0 .257.053.35.146a.503.503 0 01-.35.854H5.647l2.125 2.146a.502.502 0 01-.35.855.49.49 0 01-.351-.147l-2.969-3a.5.5 0 010-.708l2.969-3A.493.493 0 017.917 5c0 .133-.053.26-.145.354L5.647 7.5h5.732z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  )
}

export default GroupIcon
/* prettier-ignore-end */
